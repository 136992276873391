<template>
  <container ref="container" @error="init">
    <!-- 顶部 -->
    <publicHeader></publicHeader>

    <!-- 横幅 -->
    <publicBanner></publicBanner>

    <!-- 面包屑导航 -->
    <breadcrumb :router="['首页', '评论']"></breadcrumb>

    <!-- 详情 -->
    <div class="evaluateDetailPC">
      <!-- 月嫂信息 -->
      <div class="evaluateDetailPC-caregiver">
        <div class="evaluateDetailPC-caregiver-lv">
          <img src="@/assets/image/public/lv.png" />
          <span>{{ detail.servicelevel }}</span>
        </div>
        <div class="evaluateDetailPC-caregiver-sign" v-if="detail.signupdata">
          <img src="@/assets/image/public/sign.png" />
        </div>
        <img
          class="evaluateDetailPC-caregiver-avatar"
          :src="detail.userimage"
        />
        <div class="evaluateDetailPC-caregiver-info">
          <div>{{ detail.username }}</div>
          <div>{{ detail.userhome }}</div>
          <div>{{ detail.userage }}岁</div>
        </div>
        <div class="evaluateDetailPC-caregiver-star">
          <iconfont
            v-for="item in 5"
            :key="item"
            class="evaluateDetailPC-caregiver-star-icon"
            size="26px"
            :name="item <= detail.userstar ? 'star-full' : 'star'"
          />
        </div>
      </div>

      <!-- 右侧 -->
      <div class="evaluateDetailPC-right">
        <!-- 消费者 -->
        <div class="evaluateDetailPC-consumer">
          <!-- 消费者-顶部 -->
          <div class="evaluateDetailPC-consumer-header">
            <!-- 消费者-名称 -->
            <div class="evaluateDetailPC-consumer-name">
              <iconfont
                class="evaluateDetailPC-consumer-name-icon"
                size="24px"
                name="user"
              />
              {{ detail.evlname }}
            </div>
            <!-- 消费者-来自 -->
            <div class="evaluateDetailPC-consumer-from">
              {{ detail.evladdress }}
            </div>
            <!-- 消费者-时间 -->
            <div class="evaluateDetailPC-consumer-datetime">
              <iconfont
                class="evaluateDetailPC-consumer-datetime-icon"
                size="20px"
                name="time"
              />
              {{ detail.createtime }}
            </div>
          </div>
          <!-- 内容 -->
          <div
            class="evaluateDetailPC-consumer-content"
            v-html="detail.content"
          ></div>

          <!-- 图集 -->
          <div class="evaluateDetailPC-image-list">
            <img
              :src="item"
              class="evaluateDetailPC-image-item"
              v-for="(item, index) in detail.evlimages"
              :key="index"
            />
          </div>
        </div>
        <!-- 操作 -->
        <div class="evaluateDetailPC-operation">
          <!-- 操作-标签 -->
          <div class="evaluateDetailPC-operation-tag">
            <div
              class="evaluateDetailPC-operation-tag-item"
              v-for="item in detail.label"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
          <!-- 操作-按钮 -->
          <div class="evaluateDetailPC-operation-button">
            <div class="evaluateDetailPC-operation-button-item_hollow">
              <iconfont
                class="evaluateDetailPC-operation-button-item_hollow-icon"
                name="like"
                size="24px"
              />
              点赞
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 详情 -->
    <div class="evaluateDetailMobile">
      <!-- 简介 -->
      <div class="evaluateDetailMobile-introduction">
        <div class="evaluateDetailMobile-introduction-detail">
          <div class="evaluateDetailMobile-introduction-lv">
            <img src="@/assets/image/public/lv.png" />
            <span>{{ detail.servicelevel }}</span>
          </div>
          <div
            class="evaluateDetailMobile-introduction-sign"
            v-if="detail.signupdata"
          >
            <img src="@/assets/image/public/sign.png" />
          </div>
          <div class="evaluateDetailMobile-introduction-left">
            <div class="evaluateDetailMobile-introduction-avatar">
              <img
                :src="detail.userimage"
                class="evaluateDetailMobile-introduction-avatar-img"
              />
            </div>
            <!-- <div class="evaluateDetailMobile-introduction-price">查询报价</div> -->
          </div>
          <div class="evaluateDetailMobile-introduction-right">
            <div class="evaluateDetailMobile-introduction-name">
              {{ detail.username }}
            </div>
            <div class="evaluateDetailMobile-introduction-info">
              <div>{{ detail.userhome }}</div>
              <div>{{ detail.userage }}岁</div>
            </div>
            <div class="evaluateDetailMobile-introduction-data">
              <div class="evaluateDetailMobile-introduction-hot">
                <img src="@/assets/image/public/hot.png" /> {{ detail.hot }}
              </div>
              <div class="evaluateDetailMobile-introduction-evaluate">
                <img src="@/assets/image/public/evaluate.png" />
                {{ detail.goodevl }}
              </div>
            </div>
            <div class="evaluateDetailMobile-introduction-star">
              <iconfont
                v-for="item in 5"
                :key="item"
                class="evaluateDetailMobile-introduction-star-icon"
                size="40rpx"
                :name="item <= detail.userstar ? 'star-full' : 'star'"
              />
            </div>
            <div class="evaluateDetailMobile-introduction-certificates">
              <div
                class="evaluateDetailMobile-introduction-certificates-element"
                :key="item"
                v-for="item in detail.label"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 消费者 -->
      <div class="evaluateDetailMobile-consumer">
        <!-- 消费者-顶部 -->
        <div class="evaluateDetailMobile-consumer-header">
          <!-- 消费者-名称 -->
          <div class="evaluateDetailMobile-consumer-name">
            <iconfont
              class="evaluateDetailMobile-consumer-name-icon"
              size="48rpx"
              name="user"
            />
            {{ detail.adminer }}
          </div>
          <!-- 消费者-来自 -->
          <div class="evaluateDetailMobile-consumer-from">
            {{ detail.userhome }}
          </div>
          <!-- 消费者-时间 -->
          <div class="evaluateDetailMobile-consumer-datetime">
            <iconfont
              class="evaluateDetailMobile-consumer-datetime-icon"
              size="40rpx"
              name="time"
            />
            {{ detail.createtime }}
          </div>
        </div>
        <!-- 内容 -->
        <div
          class="evaluateDetailMobile-consumer-content"
          v-html="detail.content"
        ></div>
        <!-- 图集 -->
        <div class="evaluateDetailMobile-image-list">
          <img
            :src="item"
            class="evaluateDetailMobile-image-item"
            v-for="(item, index) in detail.evlimages"
            :key="index"
          />
        </div>
        <!-- 操作 -->
        <div class="evaluateDetailMobile-operation">
          <!-- 操作-按钮 -->
          <div class="evaluateDetailMobile-operation-button">
            <div class="evaluateDetailMobile-operation-button-item_hollow">
              <iconfont
                class="evaluateDetailMobile-operation-button-item_hollow-icon"
                name="like"
                size="36rpx"
              />
              点赞
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <publicFooter></publicFooter>

    <!-- 联系 -->
    <contact></contact>
  </container>
</template>

<script>
import publicHeader from "@/components/publicHeader.vue";
import publicFooter from "@/components/publicFooter.vue";
import publicBanner from "@/components/publicBanner.vue";
import contact from "@/components/contact.vue";
import breadcrumb from "@/components/breadcrumb.vue";

export default {
  name: "evaluateDetail",
  components: {
    publicHeader,
    publicFooter,
    publicBanner,
    breadcrumb,
    contact,
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    // 初始化数据
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const detail = await this.$axios("/api/mainstation/witnessdetail", {
          params: {
            id: this.$route.query.id,
          },
        });

        // 设置数据
        this.detail = detail.data;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .evaluateDetailMobile {
    display: none;
  }

  // 评论详情
  .evaluateDetailPC {
    width: 1200px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    margin: 40px auto 90px;
    display: flex;

    // 月嫂
    &-caregiver {
      width: 278px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      border-right: 1px solid #eaeaea;

      &-lv {
        color: #ed207b;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          @include cover;
          width: 34px;
          height: 34px;
        }

        span {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 20px;
          font-style: italic;
        }
      }

      // 签约
      &-sign {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 11;
      }

      // 头像
      &-avatar {
        @include cover;
        width: 128px;
        height: 128px;
        border-radius: 50%;
        margin-top: 24px;
      }

      // 信息
      &-info {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-top: 10px;
        color: #333333;

        div:not(:last-of-type)::after {
          content: "|";
          color: #adadad;
          display: inline-block;
          margin: 0 7px;
        }
      }

      // 星星
      &-star {
        display: flex;
        justify-content: center;
        margin-top: 5px;

        &-icon {
          margin: 0 2px;
          color: #ed207b;
        }
      }
    }

    // 右侧
    &-right {
      flex: 1;
      width: 0;
    }

    // 消费者
    &-consumer {
      border-bottom: 1px solid #eaeaea;
      background-color: #f8f8f8;
      padding: 20px;
      // height: 190px;

      // 顶部
      &-header {
        height: 40px;
        display: flex;
        align-items: center;
      }

      // 用户名
      &-name {
        font-size: 16px;
        color: #353535;
        display: flex;
        align-items: center;

        // 图标
        &-icon {
          color: #bfbfbf;
          margin-right: 10px;
        }
      }

      // 来自
      &-from {
        color: #a6a6a6;
        font-size: 16px;

        &::before {
          margin: 0 10px;
          content: "|";
          display: inline-block;
          color: #a8a8a8;
        }
      }

      // 时间
      &-datetime {
        font-size: 16px;
        color: #a6a6a6;
        margin-left: auto;
        display: flex;
        align-items: center;

        &-icon {
          margin-right: 10px;
        }
      }

      // 内容
      &-content {
        margin-top: 20px;
        color: #353535;
        // font-size: 14px;
        // line-height: 24px;
      }
    }

    // 操作
    &-operation {
      display: flex;
      align-items: center;
      height: 104px;

      // 标签
      &-tag {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 15px;

        // 标签-元素
        &-item {
          margin-right: 15px;
          background-color: #ed207b;
          color: #fff;
          padding: 0 10px;
          height: 28px;
          border-radius: 14px;
          font-size: 14px;
          display: flex;
          align-items: center;
        }
      }

      // 按钮
      &-button {
        display: flex;
        align-items: center;
        margin: 0 15px;
        margin-left: auto;

        // 按钮-元素
        &-item {
          margin-left: 15px;
          background-color: #ed207b;
          color: #fff;
          height: 36px;
          border-radius: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          min-width: 106px;
          box-sizing: content-box;
          cursor: pointer;

          &_hollow {
            height: 36px;
            border-radius: 18px;
            border: 1px solid #eaeaea;
            background-color: #f8f8f8;
            color: #4c4c4c;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            min-width: 106px;
            box-sizing: content-box;
            cursor: pointer;

            &-icon {
              margin-right: 10px;
            }
          }
        }
      }
    }

    // 图片
    &-image {
      &-list {
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        width: 180px;
        height: 130px;
        margin-right: 15px;
        margin-top: 15px;
        display: block;
        object-fit: cover;

        &:nth-of-type(4n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>


<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .evaluateDetailPC {
    display: none;
  }

  // 评论详情
  .evaluateDetailMobile {
    margin: rpx(40) auto rpx(90);

    // 信息
    &-introduction {
      margin: 0 rpx(30);
      margin-top: rpx(40);
      display: flex;
      border: rpx(2) solid #eaeaea;
      border-radius: rpx(10);
      position: relative;

      &-detail {
        flex: 1;
        position: relative;
        display: flex;
        padding: rpx(30);
      }

      &-left {
        width: rpx(260);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-right {
      }

      // 头像
      &-avatar {
        width: rpx(210);
        height: rpx(210);
        border-radius: 50%;
        overflow: hidden;

        &-img {
          @include cover;
        }
      }

      // 询价
      &-price {
        border-radius: 22px;
        line-height: 44px;
        text-align: center;
        background-color: #ed207b;
        color: #fff;
        width: 130px;
        height: 44px;
        margin-top: 24px;
        cursor: pointer;
      }

      // 名字
      &-name {
        color: #333333;
        font-size: rpx(28);
      }

      // 信息
      &-info {
        margin-top: rpx(10);
        display: flex;
        font-size: rpx(24);

        > div:not(:last-of-type)::after {
          content: "|";
          display: inline-block;
          margin: 0 rpx(10);
        }
      }

      // 数据
      &-data {
        display: flex;
        align-items: center;
        margin-top: 15px;
      }

      &-hot,
      &-evaluate {
        margin-right: 20px;
        font-size: rpx(24);
        display: flex;
        align-items: center;

        img {
          display: block;
          width: rpx(34);
          height: rpx(34);
          margin-right: rpx(10);
        }
      }

      &-hot {
        color: #ed207b;
      }

      &-evaluate {
        color: #9bdbda;
      }

      &-sign {
        position: absolute;
        right: 0;
        top: 0;
        width: rpx(60);
        height: rpx(60);

        img {
          @include cover;
        }
      }

      &-lv {
        position: absolute;
        left: 0;
        top: 0;
        width: rpx(60);
        height: rpx(60);
        color: #ed207b;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          @include cover;
          width: rpx(40);
          height: rpx(40);
        }

        span {
          position: absolute;
          bottom: rpx(5);
          right: rpx(5);
          font-size: rpx(24);
          font-style: italic;
        }
      }

      &-star {
        display: flex;
        margin-top: rpx(10);

        &-icon {
          margin: 0 rpx(2);
          color: #ed207b;
        }
      }

      &-certificates {
        display: flex;
        flex-wrap: wrap;
        margin-top: rpx(20);

        &-element {
          margin-right: rpx(16);
          color: #fff;
          background-color: #ed207b;
          padding: 0 rpx(6);
          line-height: rpx(40);
          font-size: rpx(24);
          border-radius: rpx(10);
        }
      }
    }

    // 消费者
    &-consumer {
      background-color: #f8f8f8;
      padding: rpx(20);
      // height: 190px;
      margin: rpx(30);
      border: rpx(2) solid #eaeaea;
      border-radius: rpx(10);

      // 顶部
      &-header {
        height: rpx(40);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      // 用户名
      &-name {
        font-size: rpx(32);
        color: #353535;
        display: flex;
        align-items: center;

        // 图标
        &-icon {
          color: #bfbfbf;
          margin-right: rpx(10);
        }
      }

      // 来自
      &-from {
        color: #a6a6a6;
        font-size: rpx(32);

        &::before {
          margin: 0 rpx(20);
          content: "|";
          display: inline-block;
          color: #a8a8a8;
        }
      }

      // 时间
      &-datetime {
        font-size: rpx(32);
        color: #a6a6a6;
        margin-left: auto;
        display: flex;
        align-items: center;

        &-icon {
          margin-right: rpx(20);
        }
      }

      // 内容
      &-content {
        margin: rpx(20) 0;
        color: #353535;
        min-height: rpx(128);
      }
    }

    // 操作
    &-operation {
      display: flex;
      align-items: center;

      // 按钮
      &-button {
        display: flex;
        align-items: center;
        margin-left: auto;

        // 按钮-元素
        &-item {
          margin-left: rpx(30);
          background-color: #ed207b;
          color: #fff;
          height: rpx(50);
          border-radius: rpx(25);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: rpx(24);
          min-width: rpx(100);
          box-sizing: content-box;
          cursor: pointer;

          &_hollow {
            height: rpx(50);
            border-radius: rpx(25);
            border: rpx(2) solid #eaeaea;
            background-color: #f8f8f8;
            color: #4c4c4c;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: rpx(24);
            min-width: rpx(140);
            box-sizing: content-box;
            cursor: pointer;

            &-icon {
              margin-right: rpx(20);
            }
          }
        }
      }
    }

    // 图片
    &-image {
      &-list {
        margin-bottom: rpx(30);
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        width: rpx(300);
        height: rpx(216);
        margin-right: rpx(30);
        margin-top: rpx(30);
        display: block;
        object-fit: cover;

        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
